import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/line-lottery.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="LINEでインスタントウィン"
		  desc="このサンプルはLINEでインスタントウィンを行う方法です。LINEの友達であれば参加できるインスタントウィンを簡単に実施できます。ブロック解除を抑制しエンゲージメントを深める施策を開催できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/line/instant-win'}
		  type={'Article'}
		  shortName="LINEでインスタントウィン"
		  createdAt="2021-11-30"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="LINEでインスタントウィン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="LINEでインスタントウィン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/line',
		  name: 'LINEキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"LINEでインスタントウィン"}
		description={<>このサンプルはLINEでインスタントウィンを行う方法です。<br/>LINEの友達であれば参加できるインスタントウィンを簡単に実施できます。応募者全員に賞品を提供するものから、当選確率を設定してくじ引きにすることもできます。<br/>ブロック解除を抑制しリテンション効果に有効です。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE, Constants.CAMPAIGN_EFFECTS.RETENTION
		  , Constants.CAMPAIGN_EFFECTS.CONNECT_LINE_BUSINESS]}
		plan={'ベーシックプラン以上'}
		promotionId="05829873ca2f731562"
		platformExample={
		  <PlatformExample
			title="その他のLINEキャンペーン"
			types={['lineReferral','lineReceipt', 'twitterToLine','lineCoupon']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples